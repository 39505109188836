import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'

import type { City } from '~/types/models'
import { DEFAULT_CURRENT_CITY } from '@/constants'

export const useCitiesStore = defineStore('cities', {
  state: () => ({
    cityName: useCookie('current_city'),
    hasConfirm: useCookie('current_city_confirmed'),
    list: ref<Array<City>>([]),
  }),
  getters: {
    current: (state) => {
      return (
        state.list.find(city => city.name === state.cityName)
        ?? state.list.find(city => city.name === 'Москва')
      )
    },
  },
  actions: {
    async getCurrent() {
      const { $dadataApi } = useNuxtApp()

      return $dadataApi
        .getAddressByIp()
        .then((data) => {
          const result = data.location?.data.city ?? DEFAULT_CURRENT_CITY

          this.cityName = result
        })
        .catch(() => {
          this.cityName = null
        })
    },
    async getList() {
      const { $api } = useNuxtApp()

      return $api.cities
        .getCities()
        .then(data => (this.list = data))
        .catch(() => (this.list = []))
    },
    confirm() {
      this.hasConfirm = 'true'
    },
    changeCurrent(newCityName: string) {
      this.cityName = newCityName
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCitiesStore, import.meta.hot))
}